import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { setDefaultOptions, format, startOfYear, endOfYear, startOfMonth, endOfMonth, startOfDay, differenceInMonths, differenceInDays, getQuarter } from 'date-fns';
import Select from 'react-select';
import { RiCalendarEventFill, RiCalendarTodoFill, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';
import { StickyContainer, Sticky } from 'react-sticky';
import { enUS } from 'date-fns/locale';
import Convert from 'color-convert';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      pageLoad: true,
      calendar: [],
      totalDays: 0,
      high: 0,
      yearList: [],
      year: '',
      month: '',
      monthActive: '',
      monthDiff: false,
      inline: false,
      dayOpen: '',
      isTablet: false,
      isLandscape: true
    }

    // this.cancellableLoad = '';

    this.copy = 2024;
    this.today = startOfDay(new Date());
    this.myRef = React.createRef();
    this.day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.month = [{
      label: 'January', value: 1
    }, {
      label: 'February', value: 2
    }, {
      label: 'March', value: 3
    }, {
      label: 'April', value: 4
    }, {
      label: 'May', value: 5
    }, {
      label: 'June', value: 6
    }, {
      label: 'July', value: 7
    }, {
      label: 'August', value: 8
    }, {
      label: 'September', value: 9
    }, {
      label: 'October', value: 10
    }, {
      label: 'November', value: 11
    }, {
      label: 'December', value: 12
    }];
    this.semester = [0, 0];
    this.quarter = [0, 0, 0, 0];
    
    this.switchInline = this.switchInline.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.addZero = this.addZero.bind(this);
    this.generateCalendar = this.generateCalendar.bind(this);
    this.toggleDay = this.toggleDay.bind(this);
    this.goToday = this.goToday.bind(this);
    this.scrollToday = this.scrollToday.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount(){
    setDefaultOptions({ locale: enUS });

    let listYear = [];

    for(let i = 54; i > 0; i--){
      const newYear = this.today.getFullYear() - i;

      listYear.push({
        label: newYear,
        value: newYear
      });
    }

    for(let i = 0; i < 55; i++){
      const newYear = this.today.getFullYear() + i;

      listYear.push({
        label: newYear,
        value: newYear
      });
    }

    const selMonth = this.month.find(x => x.value === (this.today.getMonth() + 1)),
          selYear = listYear.find(x => x.value === this.today.getFullYear());
          
    this.setState({
      yearList: listYear,
      month: selMonth,
      monthActive: selMonth,
      year: selYear
    });

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.state.year !== prevState.year
    ){
      this.generateCalendar();
    }

    if(this.state.pageLoad !== prevState.pageLoad){
      if(!this.state.pageLoad){
        this.scrollToday();
      }
    }

    if(this.state.month !== prevState.month){
      if(!this.state.pageLoad){
        if(this.state.monthDiff){
          this.setState({ monthDiff: false });
        }else{
          window.scrollTo({ top: document.getElementById(this.state.month.label).offsetTop - 59, behavior: 'smooth' });
        }
      }
    }

    if(this.state.monthActive !== prevState.monthActive){
      if(!this.state.pageLoad && this.state.monthActive.value !== this.state.month.value){
        this.setState({ month: this.state.monthActive });
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  switchInline(){
    this.setState({ inline: !this.state.inline });
  }

  selectChange(value, event){
    this.setState({ [event.name]: value });
  }

  monthChange(value){
    const findMonth = this.month.find(x => x.label === value);

    this.setState({ monthActive: findMonth });

    if(this.state.month.value !== findMonth.value){
      this.setState({ monthDiff: true });
    }
  }

  addZero(value){
    value = String(value);

    if(value.length === 1){
      value = '0' + value;
    }

    return value;
  }

  generateCalendar(){
    const startYear = startOfYear(new Date(this.state.year.value, 0, 0, 0, 0, 0)),
          endYear = endOfYear(new Date(this.state.year.value, 0, 0, 0, 0, 0)),
          monthDiff = differenceInMonths(endYear, startYear) + 1;
        
    let listMonth = [],
        countYear = 1,
        countSemester = 1,
        countQuarter = 1,
        calHigh = 0;
        
    for(let iMonth = 1; iMonth <= monthDiff; iMonth++){
      const startMonth = startOfMonth(new Date(this.state.year.value, iMonth, 0, 0, 0, 0)),
            endMonth = endOfMonth(new Date(this.state.year.value, iMonth, 0, 0, 0, 0)),
            monthDayDiff = differenceInDays(endMonth, startMonth) + 1,
            monthSkipBefore = this.day.findIndex(x => x === startMonth.toLocaleString('default', { weekday: 'long' }));

      let listDay = [],
          listSkipBefore = [],
          listSkipAfter = [],
          listSkipAfterInline = [],
          monthSkipAfter = 0;

      for(let iDay = 1; iDay <= monthDayDiff; iDay++){
        const dayTimestamp = new Date(this.state.year.value, iMonth - 1, iDay),
              name = dayTimestamp.toLocaleString('default', { weekday: 'long' });
              
        let today = false;
        
        if(this.today.getTime() === dayTimestamp.getTime()){
          today = true;
        }
        
        const hou = this.addZero(dayTimestamp.getDate()),
              min = this.addZero(dayTimestamp.getMonth() + 1),
              yearLong = dayTimestamp.getFullYear(),
              sec = this.addZero(((yearLong.toString()).substring(2, 4))),
              hex = hou + min + sec,
              rgb = Convert.hex.rgb(hex),
              rgbRed = parseFloat(((rgb[0] / 255) * 100).toFixed(0)),
              rgbGreen = parseFloat(((rgb[1] / 255) * 100).toFixed(0)),
              rgbBlue = parseFloat(((rgb[2] / 255) * 100).toFixed(0)),
              hsl = Convert.hex.hsl(hex),
              cmyk = Convert.hex.cmyk(hex),
              cmykCyan = parseFloat(((cmyk[0] / 255) * 100).toFixed(0)),
              cmykMagenta = parseFloat(((cmyk[1] / 255) * 100).toFixed(0)),
              cmykYellow = parseFloat(((cmyk[2] / 255) * 100).toFixed(0)),
              cmykBlack = parseFloat(((cmyk[3] / 255) * 100).toFixed(0));

        listDay.push({
          day: iDay,
          dayYear: countYear,
          daySemester: countSemester,
          dayQuarter: countQuarter,
          name: name,
          nameShort: name.substring(0, 3),
          today: today,
          // timestamp: dayTimestamp
          color: {
            hex: hex,
            ansi16: Convert.hex.ansi16(hex),
            ansi256: Convert.hex.ansi256(hex),
            apple: Convert.hex.apple(hex),
            cmyk: cmyk,
            cyan: cmykCyan,
            magenta: cmykMagenta,
            yellow: cmykYellow,
            black: cmykBlack,
            totalCmyk: cmykCyan + cmykMagenta + cmykYellow + cmykBlack,
            gray: Convert.hex.gray(hex),
            hcg: Convert.hex.hcg(hex),
            hsl: hsl,
            hsv: Convert.hex.hsv(hex),
            hwb: Convert.hex.hwb(hex),
            keyword: Convert.hex.keyword(hex),
            lab: Convert.hex.lab(hex),
            lch: Convert.hex.lch(hex),
            rgb: rgb,
            xyz: Convert.hex.xyz(hex),
            red: rgbRed,
            green: rgbGreen,
            blue: rgbBlue,
            totalRgb: rgbRed + rgbGreen + rgbBlue,
            hue: ((hsl[0] / 360) * 100).toFixed(0),
            bright: 100 - cmyk[3]
          }
        });

        countSemester++;
        countQuarter++;
        countYear++;
      }

      for(let iSkipBefore = 0; iSkipBefore < monthSkipBefore; iSkipBefore++){
        listSkipBefore.push('');
      }

      monthSkipAfter = 7 - ((listDay.length + listSkipBefore.length) % 7);

      if(monthSkipAfter !== 7){
        for(let iSkipAfter = 0; iSkipAfter < monthSkipAfter; iSkipAfter++){
          listSkipAfter.push('');
        }
      }
      
      for(let iSkipAfterInline = 0; iSkipAfterInline < (31 - listDay.length); iSkipAfterInline++){
        listSkipAfterInline.push('');
      }
      
      if(calHigh < listDay.length){
        calHigh = listDay.length;
      }
      
      listMonth.push({
        month: iMonth,
        name: startMonth.toLocaleString('default', { month: 'long' }),
        quarter: getQuarter(startMonth),
        semester: iMonth < 6 ? 1 : 2,
        skipBefore: listSkipBefore,
        skipAfter: listSkipAfter,
        skipAfterInline: listSkipAfterInline,
        day: listDay,
        // timestamp: startMonth
      });
      
      if(iMonth === 3 || iMonth === 6 || iMonth === 9 || iMonth === 12){
        if(iMonth === 3){
          this.quarter[0] = countQuarter - 1;
        }else if(iMonth === 6){
          this.quarter[1] = countQuarter - 1;
          this.semester[0] = countSemester - 1;

          countSemester = 1;
        }else if(iMonth === 9){
          this.quarter[2] = countQuarter - 1;
        }else if(iMonth === 12){
          this.quarter[3] = countQuarter - 1;
          this.semester[1] = countSemester - 1;

          countSemester = 1;
        }
        
        countQuarter = 1;
      }
    }
    
    this.setState({
      calendar: listMonth,
      totalDays: countYear - 1,
      high: 100 / calHigh,
      pageLoad: false
    });
  }

  toggleDay(event){
    const theDay = parseInt(event.target.dataset.day);

    let minTop = 0,
        delay = 0;

    if(this.state.dayOpen === theDay){
      this.setState({ dayOpen: '' });
      
      // minTop = 70 + 100;
      minTop = 70 + 45;
      delay = 300;
    }else{
      this.setState({ dayOpen: theDay });
      
      if(this.state.inline){
        // minTop = 90 + 45;
        minTop = 90 - 0;
      }else{
        // minTop = 90 + 60;
        // minTop = 90 - 206;
        minTop = 90 - 0;
      }
      delay = 250;
    }
    
    setTimeout(() => {
      window.scrollTo({ top: document.getElementById('day-' + theDay).getBoundingClientRect().top + window.scrollY - minTop, behavior: 'smooth' });
    }, delay);
  }

  goToday(){
    this.scrollToday();
  }

  scrollToday(){
    if(this.myRef.current){
      window.scrollTo({ top: this.myRef.current.getBoundingClientRect().top + window.scrollY - 115, behavior: 'smooth' });
    }
  }

  handleResize(){
    let landscape = true,
        table = false
        // ,
        // mobile = false
        ;

    if(window.innerWidth < window.innerHeight){
      landscape = false;
    }

    if(window.innerWidth <= 767){
      table = false;
      // mobile = true;
    }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
      table = true;
      // mobile = false;
    }

    this.setState({
      // isMobile: mobile,
      isTablet: table,
      isLandscape: landscape
    });
  }

  render(){
    const today = this.today.toLocaleString('default', { weekday: 'long' }) + ', ' + format(this.today, 'd MMMM yyyy');

    return(
      <>
        <Helmet>
          <title>HexCalendar {today}</title>
        </Helmet>

        <header>
          <ul>
            <li>
              <a href to="/">
                <Logo />
              </a>
              <div className="today" onClick={this.goToday}>
                <span>Today </span><strong>{today}</strong>
              </div>
            </li>
            <li>
              <label>Month</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="month"
                value={this.state.month}
                options={this.month}
                onChange={this.selectChange}
                menuPortalTarget={document.querySelector('body')}
              />
            </li>
            <li>
              <label>Year</label>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="year"
                value={this.state.year}
                options={this.state.yearList}
                onChange={this.selectChange}
                menuPortalTarget={document.querySelector('body')}
              />
            </li>
            <li>
              <button type="button" onClick={this.switchInline}>
                <span>View</span>
                {this.state.inline ? (<><RiCalendarTodoFill />List</>) : (<><RiCalendarEventFill />Calendar</>)}
              </button>
            </li>
          </ul>
        </header>

        <div className="pad-top"></div>

        {this.state.pageLoad ? 'Loading...' : (
          <ul className="calendar">
            <li className="year">
              {this.state.year.label}
            </li>
            {this.state.calendar.map((valMonth, indMonth) => (
              <li key={valMonth.month} id={valMonth.name}>
                <StickyContainer>
                  <Sticky
                    topOffset={this.state.isTablet ? this.state.isLandscape ? -70 : -120 : -70}
                    bottomOffset={this.state.isTablet ? this.state.isLandscape ? -70 : -120 : -70}
                  >
                    {({
                      isSticky,
                      distanceFromBottom,
                    }) => (
                      // <div className={`head ${isSticky ? 'sticky' : ''} ${distanceFromBottom > 24 && distanceFromBottom < (window.innerWidth >= 845 ? 70 : 120) ? 'was-sticky' : ''}`}>
                      <div className={`head ${isSticky ? 'sticky' : ''}`}>
                        {isSticky && this.state.monthActive.label !== valMonth.name ? (
                          <input style={{display: 'none'}} onChange={this.monthChange(valMonth.name)} />
                        ) : ''}
                        {valMonth.name} {this.state.year.label}
                      </div>
                    )}
                  </Sticky>
                  
                  <ul className={`day ${this.state.inline ? 'inline' : ''}`}>
                    {!this.state.inline && valMonth.skipBefore.map((valSkip, indSkip) => (
                      <li key={`skipBefore-${valMonth.month}-${indSkip}`}><div></div></li>
                    ))}

                    {valMonth.day.map((valDay, indDay) => (
                      <li key={`${valMonth.month}-${valDay.day}`} className={`${valDay.today ? 'today' : ''} ${this.state.dayOpen === valDay.dayYear ? 'big' : ''}`} id={`day-${valDay.dayYear}`} ref={valDay.today ? this.myRef : ''} style={{width: this.state.inline ? this.state.high + '%' : ''}}>
                        <div>
                          <div onClick={this.toggleDay} data-day={valDay.dayYear} style={{ background: '#' + valDay.color.hex }}>
                            <ul className="date-info">
                              <li>{valDay.day}</li>
                              <li>{this.state.dayOpen === valDay.dayYear ? valDay.name : this.state.inline ? valDay.nameShort : window.innerWidth >= 845 ? valDay.name : valDay.nameShort}</li>
                              <li>
                                #{valDay.color.hex}<br />
                                rgb({valDay.color.red}, {valDay.color.green}, {valDay.color.blue})
                              </li>
                            </ul>
                            <div className="quarter">
                              Q<strong>{valMonth.quarter}</strong>/S<strong>{valMonth.semester}</strong>
                            </div>
                            {this.state.dayOpen === valDay.dayYear ? (
                              <ul className="color-info">
                                <li>
                                  <span>ANSI-16</span>
                                  {valDay.color.ansi16}
                                </li>
                                <li>
                                  <span>ANSI-256</span>
                                  {valDay.color.ansi256}
                                </li>
                                <li>
                                  <span>Apple</span>
                                  apple({valDay.color.apple[0]}, {valDay.color.apple[1]}, {valDay.color.apple[2]})
                                </li>
                                <li>
                                  <span>Closest CSS Keyword</span>
                                  <div className="colorname" style={{ background: valDay.color.keyword }}></div>{valDay.color.keyword}
                                </li>
                                <li>
                                  <span>CMYK</span>
                                  cmyk({valDay.color.cmyk[0]}, {valDay.color.cmyk[1]}, {valDay.color.cmyk[2]}, {valDay.color.cmyk[3]})
                                </li>
                                <li>
                                  <span>Gray</span>
                                  {valDay.color.gray[0]}
                                </li>
                                <li>
                                  <span>HCG</span>
                                  hcg({valDay.color.hcg[0]}, {valDay.color.hcg[1]}, {valDay.color.hcg[2]})
                                </li>
                                <li>
                                  <span>HSL</span>
                                  hsl({valDay.color.hsl[0]}, {valDay.color.hsl[1]}, {valDay.color.hsl[2]})
                                </li>
                                <li>
                                  <span>HSV</span>
                                  hsv({valDay.color.hsv[0]}, {valDay.color.hsv[1]}, {valDay.color.hsv[2]})
                                </li>
                                <li>
                                  <span>HWB</span>
                                  hwb({valDay.color.hwb[0]}, {valDay.color.hwb[1]}, {valDay.color.hwb[2]})
                                </li>
                                <li>
                                  <span>LAB</span>
                                  lab({valDay.color.lab[0]}, {valDay.color.lab[1]}, {valDay.color.lab[2]})
                                </li>
                                <li>
                                  <span>LCH</span>
                                  lch({valDay.color.lch[0]}, {valDay.color.lch[1]}, {valDay.color.lch[2]})
                                </li>
                                <li>
                                  <span>XYZ</span>
                                  xyz({valDay.color.xyz[0]}, {valDay.color.xyz[1]}, {valDay.color.xyz[2]})
                                </li>
                                <li className="lay2 clear same">
                                  <span>Hue <strong>{valDay.color.hue}%</strong></span>
                                  <div className="chart">
                                    <span className="huepicker">
                                      <div className="pointer" style={{ left: valDay.color.hue + '%' }}></div>
                                    </span>
                                  </div>
                                </li>
                                <li className="lay2 same">
                                  <span>Brightness <strong>{valDay.color.bright}%</strong></span>
                                  <div className="chart">
                                    <span className="darkpicker">
                                      <div className="pointer" style={{ left: valDay.color.bright + '%' }}></div>
                                    </span>
                                  </div>
                                </li>
                                <li className="lay2">
                                  <ul>
                                    <li className="lay3">
                                      <span>RGB - Red <strong>{valDay.color.red}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'red', width: valDay.color.red + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="lay3">
                                      <span>RGB - Green <strong>{valDay.color.green}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'green', width: valDay.color.green + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="lay3">
                                      <span>RGB - Blue <strong>{valDay.color.blue}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'blue', width: valDay.color.blue + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="layfull">
                                      <span>RGB - Scheme</span>
                                      <div className="chart multi">
                                        <span style={{ background: 'red', width: ((valDay.color.red / valDay.color.totalRgb) * 100) + '%' }}></span>
                                        <span style={{ background: 'green', width: ((valDay.color.green / valDay.color.totalRgb) * 100) + '%' }}></span>
                                        <span style={{ background: 'blue', width: ((valDay.color.blue / valDay.color.totalRgb) * 100) + '%' }}></span>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li className="lay2">
                                  <ul>
                                    <li className="lay4">
                                      <span>CMYK - Cyan <strong>{valDay.color.cmyk[0]}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'cyan', width: valDay.color.cyan + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="lay4">
                                      <span>CMYK - Magenta <strong>{valDay.color.cmyk[1]}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'magenta', width: valDay.color.magenta + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="lay4">
                                      <span>CMYK - Yellow <strong>{valDay.color.cmyk[2]}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'yellow', width: valDay.color.yellow + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="lay4">
                                      <span>CMYK - Black <strong>{valDay.color.cmyk[3]}%</strong></span>
                                      <div className="chart">
                                        <span style={{ background: 'black', width: valDay.color.black + '%' }}></span>
                                      </div>
                                    </li>
                                    <li className="layfull">
                                      <span>CMYK - Scheme</span>
                                      <div className="chart multi">
                                        <span style={{ background: 'cyan', width: ((valDay.color.cyan / valDay.color.totalCmyk) * 100) + '%' }}></span>
                                        <span style={{ background: 'magenta', width: ((valDay.color.magenta / valDay.color.totalCmyk) * 100) + '%' }}></span>
                                        <span style={{ background: 'yellow', width: ((valDay.color.yellow / valDay.color.totalCmyk) * 100) + '%' }}></span>
                                        <span style={{ background: 'black', width: ((valDay.color.black / valDay.color.totalCmyk) * 100) + '%' }}></span>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            ) : ''}
                            <div className="value">
                              <ul>
                                <li>Day <strong>{valDay.dayQuarter}</strong> in quarter</li>
                                <li>Day <strong>{valDay.daySemester}</strong> in semester</li>
                                <li>Day <strong>{valDay.dayYear}</strong> in year</li>
                              </ul>
                            </div>
                            <div className="percent">
                              <ul>
                                <li>{parseFloat(((valDay.daySemester / this.semester[valMonth.semester - 1]) * 100).toFixed(2))}%</li>
                                <li>{parseFloat(((valDay.dayQuarter / this.quarter[valMonth.quarter - 1]) * 100).toFixed(2))}%</li>
                                <li>{parseFloat(((valDay.dayYear / this.state.totalDays) * 100).toFixed(2))}%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                    
                    {!this.state.inline ? valMonth.skipAfter.map((valSkip, indSkip) => (
                      <li key={`skipAfter-${valMonth.month}-${indSkip}`}><div></div></li>
                    )) : valMonth.skipAfterInline.map((valSkip, indSkip) => (
                      <li key={`skipAfter-${valMonth.month}-${indSkip}`} style={{width: this.state.inline ? this.state.high + '%' : ''}}><div></div></li>
                    ))}
                  </ul>
                </StickyContainer>
              </li>
            ))}
          </ul>
        )}

        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">Hex Calendar</a> by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">HexCalendar</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;